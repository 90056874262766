import React, { useState, useEffect } from 'react';
import FilterComponent from '/src/components/plugin/jobs/filter.jsx';
import { useTranslations } from '../../../i18n/utils.ts';
import { ui } from '../../../i18n/ui.ts';
import useQueryParam from '/src/lib/react/useQueryParams.js';

const CACHE_KEY = 'tableData';
const CACHE_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds
const JOB_URL = 'https://api.smartrecruiters.com/v1/companies/ATParchitekteningenieure/postings'

const JobList = ({ language, header, filterClasses, listClasses, jobLink, showFilter, filter }) => {
  const t = useTranslations(language, ui);
  const [jobs, setJobs] = useState([]);
  const [filterSettings, setFilterSettings] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [noOfItems, setNoOfItems] = useState(20);
  const [filterEnabled, setFilterEnabled] = useState(showFilter);

  const filterDepartment = useQueryParam('department');
  const filterLocation = useQueryParam('location');
  const filterCompany = useQueryParam('company');
  const filterStartAs = useQueryParam('startAs');

  //console.log('JobList', {filterDepartment, filterLocation, filterCompany, filterStartAs});
  useEffect(() => {

    const fetchData = async () => {
      const jobs = await window?.jobsData.getJobs();
      const filtersettings = await window?.jobsData.getFilterSettings();
      //console.log('JobList.jsx->fetchData ##66', { data: jobs });
      setJobs(jobs);
      setFilterSettings(filtersettings);
    };


    fetchData();

  }, []);

  useEffect(() => {
   
    const newFilter = {...filter};
    if (filterDepartment) newFilter?.department ? newFilter?.department.push(filterDepartment) : newFilter.department = [filterDepartment];
    if (filterLocation) newFilter?.location ? newFilter?.location.push(filterLocation) : newFilter.location = [filterLocation];
    if (filterStartAs) newFilter?.startAs ? newFilter?.startAs.push(filterStartAs) : newFilter.startAs = [filterStartAs];
    if (filterCompany) newFilter?.company ? newFilter?.company.push(filterCompany) : newFilter.company = [filterCompany];

    if(newFilter?.department?.[0] || newFilter?.location?.[0] || newFilter?.company?.[0] || newFilter?.startAs?.[0]) setFilterEnabled(false);
    //console.log('JobList.jsx->useEffect ##66', { newFilter });
    setCurrentFilter(newFilter);

  }, [filter, filterDepartment, filterLocation, filterCompany, filterStartAs]);


  useEffect(() => {
    let noOfAvailableItems = 0;
    let filterIsSet = false;
    const filtered = jobs.filter((item) => {
      let allMatched = true;
      Object.keys(currentFilter).forEach((type) => {
        //if (item?.id === "cd4aa673-7d75-4fa9-83e2-c414255b59ae") console.log('JobList.jsx->useEffect ##66 ----#1', { allMatched, type, typeFilter: currentFilter[type], length: currentFilter[type]?.length });
        let show = currentFilter[type]?.length === 0;
        currentFilter[type].forEach((value) => {
          filterIsSet = true;
          if (item?.[type]?.id === value) show = true;
          //if (item?.id === "cd4aa673-7d75-4fa9-83e2-c414255b59ae") console.log('JobList.jsx->useEffect ##66 #2', { allMatched, show, type, itTypeValue: item?.[type]?.id, value });

        });
        if (!show) allMatched = false;
      });
      if (allMatched) noOfAvailableItems++;
      return allMatched;
    });

    //console.log('JobList.jsx->useEffect ##66 #3', { filterIsSet, filtered, noOfAvailableItems });
    setFilteredData(filterIsSet ? filtered : jobs);
  }, [jobs, currentFilter]);


  const openJobDetails = (detailsUrl, postingUrl) => {
    if (postingUrl) {
      window.open(postingUrl, '_blank');
    } else {
      window?.jobsData.getJobDetails(detailsUrl).then((details) => {
        window.open(details?.postingUrl, '_blank');
      });
    }
  }

  const showMore = filteredData?.length > noOfItems;
  const shownItems = filteredData.slice(0, noOfItems);
  //console.log('JobList.jsx->render ##66', {filteredDataLength: filteredData?.length, noIfItems: noOfItems});
  return (
    <>
      {shownItems?.length > 0 && filterEnabled && <section className="sticky transition-all border-b border-white bg-gray-50 z-100 top-header-mobile lg:top-header">
        <FilterComponent filterSettings={filterSettings} startFilter={filter} classes={filterClasses} t={t} applyFilterCB={setCurrentFilter} />
      </section>}
      <section className={filterEnabled ? listClasses : ""}>
        {(!shownItems || shownItems.length === 0) && 
          <p class="lg:text-xl" dangerouslySetInnerHTML={{__html:t("jobs.no.results.message").replace("{{JOBS_LINK}}", jobLink)}}></p>
        }
        {shownItems?.length > 0 && <div className="@container/stackedlist ">
          <h5 className="text-xl transition-all lg:text-3xl">{header}</h5>
          <div className="divide-y divide-gray-200">
            {shownItems.map((item, index) => (
              <div key={"container-" + item?.id} className="py-2.5 lg:pb-5">
                <div className="@container/stackedlistitem ">
                  <div className="relative grid grid-cols-12 gap-y-2.5 gap-x-5 @lg/stackedlistitem:gap-12">
                    <div key={item?.id} data-sr-id={item?.id} className="col-span-10 @lg/stackedlistitem:col-span-11 grid grid-cols-10 gap-y-2.5 gap-x-5 @lg/stackedlistitem:gap-12">
                      <div key="title" className="col-span-10 @lg/stackedlistitem:col-span-4">
                        <h5 className="transition-all @sm/stackedlistitem:text-xl @lg/stackedlistitem:text-2xl">
                          <a onClick={() => openJobDetails(item?.detailsUrl, item?.postingUrl)} className="cursor-pointer hover:text-red hover:underline">
                            {item?.title}
                          </a>
                        </h5>
                      </div>

                      <div key="department" className="col-span-5 @lg/stackedlistitem:col-span-3">
                        <p key="department1" data-type_id={item?.department?.id} className="text-xs transition-all @md/stackedlistitem:text-sm @lg/stackedlistitem:text-base @layout/stackedlistitem:text-xl">
                          {item?.department?.label}
                        </p>
                        <p key="department2" data-type_id={item?.startAs?.id} className="text-xs transition-all @md/stackedlistitem:text-sm @lg/stackedlistitem:text-base @layout/stackedlistitem:text-xl text-gray">
                          {item?.startAs?.label}
                        </p>
                      </div>

                      <div key="location" className="col-span-5 @lg/stackedlistitem:col-span-3">
                        <p key="location1" data-type_id={item?.location?.id} className="text-xs transition-all @md/stackedlistitem:text-sm @lg/stackedlistitem:text-base @layout/stackedlistitem:text-xl">
                          {item?.location?.label}
                        </p>
                        <p key="location2" data-type_id={item?.company?.id} className="text-xs transition-all @md/stackedlistitem:text-sm @lg/stackedlistitem:text-base @layout/stackedlistitem:text-xl text-gray">
                          {item?.company?.label}
                        </p>
                      </div>
                      

                    </div>
                    <div key="button" className="col-span-2 text-right @lg/stackedlistitem:col-span-1"> 
                        <button onClick={() => openJobDetails(item?.detailsUrl, item?.postingUrl)} className="group/button inline-flex items-center leading-none hover:cursor-pointer lg:leading-none text-base lg:text-lg gap-2 lg:gap-2.5 p-1.5 lg:p-2 rounded-full  text-red hover:text-white bg-white hover:bg-red border border-red hover:border-red focus:outline-none transition-all absolute right-0" type="button">
                          <span className="button-icon size-6 lg:size-8 "> 
                            <img className="transition-all size-6 lg:size-8 filter-red group-hover/button:filter-white" src="/imgs/icons/phosphor_light/caret-right-light.svg" /> 
                          </span>
                        </button>
                      </div>
                  </div>
                </div>
              </div>

            ))}

            {showMore && <div key="showmore1" className="pt-12 text-center "> 
              <button onClick={()=>setNoOfItems(noOfItems + 1000)}className="group/button inline-flex items-center leading-none hover:cursor-pointer lg:leading-none text-base lg:text-lg gap-2 lg:gap-2.5 px-2 py-1.5 lg:px-4 lg:py-2 rounded-md  text-gray hover:text-red bg-white hover:bg-white border border-gray hover:border-red focus:outline-none transition-all " type="button">
              <span className="button-icon size-6 lg:size-8 "> 
                <img className="transition-all size-6 lg:size-8 filter-gray group-hover/button:filter-red" src="/imgs/icons/phosphor_light/plus-light.svg" /> 
              </span>
              <span className="flex items-center h-6 transition-all whitespace-nowrap button-caption lg:h-8 ">{t("more.elements")}</span>
              </button> 
            </div>}
          </div>
        </div>}
      </section>
    </>
  );
};

export default JobList;